<template>
  <v-app>
    <v-navigation-drawer app permanent width="395">
      <top-bar
        @clickRestore="restore"
        @changeKey="changeKey"
        @disableBtns="disableBtns"
        @clickReplay="clickReplay"
        @clickShake="clickShake"
        :btnDisabled="btnDisabled"
        :voidRatio="voidRatio"
        :sumMass="sumMass"
        :settings="settings"
        :ballProps="ballProps"
      />
    </v-navigation-drawer>
    <v-main app>
      <pyade
        @changeVoidRatio="setVoidRatio"
        @changeSumMass="setSumMass"
        @activeBtns="activeBtns"
        @finishReplay="finishReplay"
        @finishShake="finishShake"
        :settings="settings"
        :ballProps="ballProps"
        :replay="replay"
        :shake="shake"
        :key="refreshKey"
      />
      <!-- <pyade-webclient
        @changeVoidRatio="setVoidRatio"
        @changeSumMass="setSumMass"
        @activeBtns="activeBtns"
        @finishReplay="finishReplay"
        @finishShake="finishShake"
        :settings="settings"
        :ballProps="ballProps"
        :replay="replay"
        :shake="shake"
        :key="refreshKey"
      /> -->
    </v-main>
  </v-app>
</template>

<script>
import Pyade from '@/components/Pyade.vue'
import TopBar from '@/components/TopBar.vue'
// import PyadeWebclient from '@/components/PyadeWebclient.vue'
import axios from 'axios'
import { defaultBallProps, defaultSettings } from '@/config/defaultConfig.js'
import { localStore, copy } from '@/utils/storeUtils.js'

export default {
  name: 'GravelThree',
  components: {
    Pyade,
    // PyadeWebclient,
    TopBar
  },
  data() {
    return {
      refreshKey: 0,
      replay: true,
      shake: false,
      btnDisabled: false,
      sumMass: 0,
      voidRatio: 0,
      storeBallProps: localStore('ballProps'),
      storeSettings: localStore('settings'),
      settings: null,
      ballProps: [],
    }
  },
  methods: {
    setSumMass: function(m) {
      this.sumMass = m
    },
    setVoidRatio: function(v) {
      this.voidRatio = v
    },
    restore: function() {
      this.settings = copy(defaultSettings)
      this.ballProps = copy(defaultBallProps)
      this.storeSettings(defaultSettings)
      this.storeBallProps(defaultBallProps)
    },
    disableBtns: function() {
      this.btnDisabled = true
    },
    activeBtns: function() {
      this.btnDisabled = false
    },
    changeKey: function() {
      this.refreshKey++
    },
    clickReplay: function() {
      this.replay = true
    },
    finishReplay: function() {
      this.replay = false
    },
    clickShake: function() {
      this.shake = true
    },
    finishShake: function() {
      this.shake = false
    },
  },
  created() {
    // const uri = 'https://ips-1.hzdatalink.com/gateway/user/api/v1/auth/token'
    const uri = 'https://gateway.hzdatalink.com/user/api/v1/auth/token/new'
    axios
      .post(uri, {
        username: '18158505584',
        password: '123456',
        vcode: '',
        unlockCode: '',
        tid: '',
        clientType: 'APP',
        clientInfo: 'WeChat Application',
        platform: 'APPSTONE',
      })
      .then((response) => {
        localStorage.setItem('token', response.data.token)
        // axios
        //   .post(
        //     uri,
        //     {
        //       tid: '10002zxczh',
        //       clientType: 'APP',
        //       platform: 'APPSTONE',
        //     },
        //     { headers: { Authorization: 'Bearer ' + response.data.token } }
        //   )
        //   .then((res) => {
        //     console.log('2 ' + res.data.token)
        //   })
      })
      .catch((err) => console.log(err))
    let localSettings = localStorage.getItem('settings')
    if (localSettings === null) {
      localSettings = defaultSettings
      this.storeSettings(defaultSettings)
    } else {
      localSettings = JSON.parse(localSettings)
    }
    this.settings = copy(localSettings)
    let localBallProps = localStorage.getItem('ballProps')
    if (localBallProps === null) {
      localBallProps = defaultBallProps
      this.storeBallProps(defaultBallProps)
    } else {
      localBallProps = JSON.parse(localBallProps)
    }
    this.ballProps = copy(localBallProps)
  },
}
</script>
