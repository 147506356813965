import { Mesh, SphereGeometry, BufferGeometry } from "three";

const drawBall = (radius) => {
  const sphere = new SphereGeometry(radius, 8, 6);
  return (material = new BufferGeometry()) => {
    return new Mesh(sphere, material);
  };
};

export default drawBall;
