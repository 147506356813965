<template>
  <div>
    <v-progress-linear
      color="light-blue"
      height="15"
      :value="progress"
      striped
    ></v-progress-linear>
    <div id="canvas-1" />
  </div>
</template>

<script>
import * as THREE from 'three'
import axios from 'axios'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import { getCorridor, capVolume } from '@/utils/mathUtils.js'
import { getUriPrefix } from '@/config/defaultConfig.js'
import getBallColor from '@/config/colorConfig.js'

import createScene from '@/three/scene/Scene.js'
import createCamera from '@/three/camera/Camera.js'
import createRenderer from '@/three/renderer/Renderer.js'
import drawBox from '@/three/scene/Box.js'
import drawBall from '@/three/geometry/Ball.js'

export default {
  name: 'Pyade',
  props: {
    settings: {
      type: Object,
      default: null,
    },
    ballProps: {
      type: Array,
      default: () => [],
    },
    replay: {
      type: Boolean,
    },
    shake: {
      type: Boolean,
    },
  },
  data() {
    return {
      progress: 0,
    }
  },
  mounted() {
    this.scene = null
    this.timer = null
    this.renderer = null
    this.start()
  },
  methods: {
    replaying: function() {
      this.$emit('finishReplay')
    },
    setSumMass: function(sumMass) {
      this.$emit('changeSumMass', sumMass)
    },
    finishShake: function() {
      this.$emit('finishShake')
    },
    finish: function(voidRatio) {
      this.$emit('changeVoidRatio', voidRatio)
      this.$emit('activeBtns')
    },
    getMaxGrainSize: function() {
      let maxGrainSize = -1
      this.ballProps.forEach((ballProp) => {
        if (ballProp.grainSize > maxGrainSize) {
          maxGrainSize = ballProp.grainSize
        }
      })
      return maxGrainSize
    },

    start: function() {
      const { boxX, boxY, boxZ, faceDensity } = this.settings
      let [transX, transZ] = [boxX / 2.0, boxZ / 2.0]

      let width = window.innerWidth - 415 //窗口宽度
      let height = window.innerHeight - 20 //窗口高度

      const scene = createScene()
      this.scene = scene
      const camera = createCamera(
        width,
        height,
        scene.position,
        boxX,
        boxY,
        boxZ
      )
      let renderer = createRenderer(width, height)
      const box = drawBox({
        x: boxX,
        y: boxY,
        z: boxZ,
      })

      const balls = new THREE.Group()
      balls.name = 'balls'
      //创建球体几何对象
      let sumCount = 0
      for (let i = 0; i < this.ballProps.length; i++) {
        const count = this.ballProps[i].count
        const radius = this.ballProps[i].grainSize / 2
        sumCount += count
        for (let j = 0; j < count; j++) {
          const darwSizedBall = drawBall(radius)
          const ballMaterial = new THREE.MeshLambertMaterial({
            color: getBallColor(radius * 2),
            // wireframe: true,
          })

          let ball = darwSizedBall(ballMaterial)
          ball.userData.radiusNumber = i
          ball.userData.radius = radius
          balls.add(ball)
        }
      }

      const initUri =
        getUriPrefix() +
        '/simulation' +
        '?token=' +
        localStorage.getItem('token')

      const maxGrainSize = this.getMaxGrainSize()
      let second = 0 // 物体运动时间间隔

      const deltaTime = 0.03
      let oneSec = 0 // 整秒计时
      let step = 0 // 当前帧下标
      let stable = false // 稳定态(动画停留在最后一帧)
      let sum = 0 // 渲染时间间隔计时
      let stage = 0
      const run = (response) => {
        stage = 0
        this.progress = 100
        let positionData = response.data
        let sumStep = positionData.length
        const clock = new THREE.Clock()
        // 渲染函数
        const render = () => {
          const delta = clock.getDelta()
          if (this.replay === true) {
            step = 0
            this.replaying()
            stable = false
          }
          second += delta
          if (this.shake === true) {
            this.progress = 0
            this.finishShake()
            const shakeUri =
              getUriPrefix() +
              '/simulation/shake' +
              '?token=' +
              localStorage.getItem('token')
            axios
              .post(shakeUri, {
                settings: this.settings,
                ballProps: this.ballProps,
                ballPositions: positionData[sumStep - 1],
              })
              .then((res) => {
                const resUri = res.data
                console.log(`shakeUri ${resUri}`)
                axios
                  .get(resUri)
                  .then((shakeResponse) => {
                    this.progress = 100
                    stage = 1
                    positionData = shakeResponse.data
                    sumStep = positionData.length
                    stable = false // 运动态
                    step = 0 // 帧下标归零
                    oneSec = 0 // 求解器重新开始计时

                    // 渲染器重新开始计时
                    sum = 0
                    second = 0
                  })
                  .catch((err) => console.log(err))
              })
              .catch((err) => console.log(err))
          }
          if (second >= deltaTime && stable === false) {
            second = 0
            if (step < sumStep - 1) {
              let [maxX, maxZ, minX, minZ] = [0, 0, 0, 0]
              const stepPositions = positionData[step]
              for (let i = 0; i < sumCount; i++) {
                balls.children[i].position.x = stepPositions[i][0]
                balls.children[i].position.z = stepPositions[i][1]
                balls.children[i].position.y = stepPositions[i][2]

                // 检测容器边界偏移
                if (
                  stage === 1 &&
                  stepPositions[i][2] > 0 &&
                  stepPositions[i][2] <= boxY
                ) {
                  const radius = balls.children[i].userData.radius
                  const [borderMinX, borderMaxX] = getCorridor(
                    stepPositions[i][0],
                    radius
                  )
                  const [borderMinZ, borderMaxZ] = getCorridor(
                    stepPositions[i][1],
                    radius
                  )
                  minX = Math.min(borderMinX, minX)
                  maxX = Math.max(borderMaxX, maxX)
                  minZ = Math.min(borderMinZ, minZ)
                  maxZ = Math.max(borderMaxZ, maxZ)
                }
              }
              if (stage === 1) {
                // 计算x-z平面下box底面中心点的偏移量
                const [dX, dZ] = [
                  (maxX - minX) / 2.0 + minX,
                  (maxZ - minZ) / 2.0 + minZ,
                ]
                box.translateX(dX - transX)
                box.translateZ(dZ - transZ)
                transX = dX
                transZ = dZ
              }
              step++
            } else {
              stable = true
              console.log('finish')
            }
          }
          oneSec += delta
          if (oneSec >= 1) {
            oneSec = 0
            const ballsInBox = balls.children
              .map((ball) => {
                return {
                  position: ball.position,
                  radius: ball.userData.radius,
                }
              })
              .filter((ball) => ball.position.y > 0 && ball.position.y <= boxY)
            console.log(`${ballsInBox.length} balls in box`)
            let maxHeight = 0
            let sumMass = 0
            ballsInBox.forEach((ball) => {
              let height = ball.position.y + ball.radius
              sumMass +=
                (faceDensity * 4 * Math.PI * Math.pow(ball.radius, 3)) / 3
              if (height > maxHeight) {
                maxHeight = height
              }
            })
            this.setSumMass(sumMass / 1000)

            // 截取容器内最大粒径的高度为下界
            const lowLimit = maxGrainSize
            // 截取容器内最大高度减去最大粒径为上界
            const heightLimit = maxHeight - maxGrainSize
            const calcHeight = heightLimit - lowLimit
            // console.log(
            //   `lowLimit ${lowLimit} heightLimit ${heightLimit}  calcHeight ${calcHeight}`
            // )
            let voidRatio = 0
            // 球的堆积高度过低
            if (calcHeight < maxGrainSize) {
              voidRatio = -1
            } else {
              const sumVolume = boxX * boxZ * calcHeight
              let ballVolume = 0
              // console.log(`sumVolume ${sumVolume}`)
              ballsInBox.forEach((ball) => {
                const radius = ball.radius
                const y = ball.position.y
                let volume = (4 * Math.PI * Math.pow(radius, 3)) / 3
                const down = y - radius
                const high = y + radius
                if (down >= heightLimit || high <= lowLimit) {
                  return
                }
                const capRadiusVolume = capVolume(radius)
                if (down < lowLimit) {
                  volume -= capRadiusVolume(lowLimit - down)
                }
                if (high > heightLimit) {
                  volume -= capRadiusVolume(high - heightLimit)
                }
                ballVolume += volume
              })
              // console.log(`ballVolume ${ballVolume}`)
              voidRatio = 1 - ballVolume / sumVolume
            }
            // console.log(`voidRatio ${voidRatio}`)
            this.finish(voidRatio)
          }
          sum += delta
          if (sum >= deltaTime) {
            sum = 0
            //执行渲染操作 指定场景、相机作为参数
            if (renderer) {
              requestAnimationFrame(render)
              renderer.render(scene, camera)
            } else {
              return
            }
          }
        }

        // 加入几何体到情景中
        scene.add(box)
        scene.add(balls)

        const controls = new OrbitControls(camera, renderer.domElement)
        controls.addEventListener('change', render)

        document.getElementById('canvas-1').appendChild(renderer.domElement) //body元素中插入canvas对象

        renderer.setAnimationLoop(render)
      }

      const sumTime = Math.max(sumCount / 60, 3)
      const timeStep = 100 / sumTime
      this.timer = setInterval(
        () => (this.progress = Math.min(this.progress + timeStep, 100)),
        2000
      )
      axios
        .post(initUri, { settings: this.settings, ballProps: this.ballProps })
        .then((res) => {
          const resUri = res.data
          console.log(`resUri ${resUri}`)
          axios
            .get(resUri)
            .then(run)
            .catch((err) => console.log(err))
        })
        .catch((err) => console.log(err))
      this.scene = scene
      this.renderer = renderer
    },
    clearCache: function(item) {
      if (item.geometry) {
        item.geometry.dispose()
      }
      if (item.material) {
        item.material.dispose()
      }
    },
    removeObj: function(obj) {
      let arr = obj.children.filter((x) => x)
      arr.forEach((item) => {
        if (item.children.length) {
          this.removeObj(item)
        } else {
          this.clearCache(item)
          item.clear()
        }
      })
      obj.clear()
      arr = null
    },
    destroyed: function() {
      this.removeObj(this.scene)
      this.renderer.renderLists.dispose()
      this.renderer.dispose()
      this.renderer.forceContextLoss()
      this.renderer.domElement = null
      this.renderer.content = null
      this.renderer = null
      THREE.Cache.clear()
    },
  },
  beforeDestroy: function() {
    clearInterval(this.timer)
    const canvas = document.getElementById('canvas-1')
    let child = canvas.childNodes
    for (let i = 0; i < child.length; i++) {
      canvas.removeChild(child[i])
    }
    this.destroyed()
  },
}
</script>
