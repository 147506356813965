const defaultSettings = {
  boxX: 20,
  boxY: 20,
  boxZ: 20,
  g: 9.8,
  faceDensity: 2.67,
};
const defaultBallProps = [
  {
    count: 300,
    grainSize: 1,
  },
  {
    count: 300,
    grainSize: 1.2,
  },
  {
    count: 200,
    grainSize: 1.4,
  },
  {
    count: 200,
    grainSize: 1.7,
  },
  {
    count: 300,
    grainSize: 2.2,
  },
  {
    count: 200,
    grainSize: 2.4,
  },
];

const uriProto = ['http', 'https', 'ws', 'wss'][1]

const uriPrefix = [
  "127.0.0.1:7777",
  "192.9.200.125:8877",
  "gateway-1.hzdatalink.com/cube-three",  // 正式
  "gateway.hzdatalink.com/cube-three",    // 测试
][3]

const getUriPrefix = () => uriProto + '://' + uriPrefix

export { defaultSettings, defaultBallProps, getUriPrefix };
