// import { OrthographicCamera } from "three";
import { PerspectiveCamera } from "three";

//创建相机对象
export default function createCamera(
  width,
  height,
  position,
  boxX,
  boxY,
  boxZ
) {
  /**
   * 相机设置
   */

  // 正投影相机
  // let k = width / height; //窗口宽高比
  // let s = (boxX + boxY + boxZ) / 3; //三维场景显示范围控制系数，系数越大，显示的范围越大
  // const camera = new OrthographicCamera(-s * k, s * k, s, -s, 1, 1000);
  // camera.position.set(1.3 * boxX, 1.4 * boxY, 1.2 * boxZ);

  // 透视投影相机
  const camera = new PerspectiveCamera(80, width / height, 0.1, 500);
  camera.position.set(1.3 * boxX, 1.4 * boxY, 1.2 * boxZ);

  camera.name = "camera";
  return camera;
}
