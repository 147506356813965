import * as THREE from "three";
/**
 * 创建场景对象Scene
 */
export default function createScene() {
  let scene = new THREE.Scene();
  scene.name = "scene";
  //坐标轴 x:红 y:绿 z:蓝
  scene.add(new THREE.AxesHelper(270));

  /**
   * 光源设置
   */
  let point = new THREE.PointLight(0xffffff);
  point.position.set(0, 500, 0); //点光源位置
  //点光源
  scene.add(point);
  //环境光
  scene.add(new THREE.AmbientLight(0xffffff));

  return scene;
}
