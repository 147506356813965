const getBallColor = (grainSize) => {
  if (grainSize <= 1) {
    return 0xe53935;
  } else if (grainSize <= 1.2) {
    return 0x5e35b1;
  } else if (grainSize <= 1.4) {
    return 0x1e88e5;
  } else if (grainSize <= 1.6) {
    return 0x43a047;
  } else if (grainSize <= 1.8) {
    return 0xfdd835;
  } else if (grainSize <= 2.0) {
    return 0xf4511e;
  } else if (grainSize <= 2.2) {
    return 0x795548;
  } else if (grainSize <= 2.4) {
    return 0x222222;
  }
};

export default getBallColor ;
