import * as THREE from "three";
import drawPlane from "../geometry/Plane.js";
import drawLines from "../geometry/Lines.js";

export default function drawBox({ x, y, z }) {
  x += 0.5
  y += 0.5
  z += 0.5
  let group = new THREE.Group();
  group.name = "box";
  // 侧面
  const sidePlaneMaterial = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    side: THREE.DoubleSide,
    opacity: 0.5,
    transparent: true,
  });
  const drawSidePlane = drawPlane(sidePlaneMaterial);

  const sidePlane1 = drawSidePlane(x)(y);
  sidePlane1.translateX(x / 2);
  sidePlane1.translateY(y / 2);
  group.add(sidePlane1);
  const sidePlane2 = drawSidePlane(x)(y);
  sidePlane2.translateX(x / 2);
  sidePlane2.translateY(y / 2);
  sidePlane2.translateZ(z);
  group.add(sidePlane2);
  const sidePlane3 = drawSidePlane(z)(y);
  sidePlane3.translateZ(z / 2);
  sidePlane3.translateY(y / 2);
  sidePlane3.rotateY(Math.PI / 2);
  group.add(sidePlane3);
  const sidePlane4 = drawSidePlane(z)(y);
  sidePlane4.translateX(x);
  sidePlane4.translateZ(z / 2);
  sidePlane4.translateY(y / 2);
  sidePlane4.rotateY(Math.PI / 2);
  group.add(sidePlane4);

  // 底面
  const bottomPlaneMaterial = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    side: THREE.DoubleSide,
    opacity: 0.8,
    transparent: true,
  });
  const bottomPlane = drawPlane(bottomPlaneMaterial)(x)(z);
  bottomPlane.name = "bottom";
  bottomPlane.translateX(x / 2);
  bottomPlane.translateZ(z / 2);
  bottomPlane.rotateX(Math.PI / 2);
  group.add(bottomPlane);

  // 画边线
  const sideLineMaterial = new THREE.LineBasicMaterial({
    // color: 0xffa000,
    color: Math.random() * 0xffffff,
  });
  const points = [
    new THREE.Vector3(0, 0, z),
    new THREE.Vector3(x, 0, z),
    new THREE.Vector3(x, 0, 0),
    new THREE.Vector3(0, 0, 0),
    new THREE.Vector3(0, 0, z),
    new THREE.Vector3(0, y, z),
    new THREE.Vector3(x, y, z),
    new THREE.Vector3(x, 0, z),
    new THREE.Vector3(x, 0, 0),
    new THREE.Vector3(x, y, 0),
    new THREE.Vector3(x, y, z),
    new THREE.Vector3(0, y, z),
    new THREE.Vector3(0, y, 0),
    new THREE.Vector3(0, 0, 0),
    new THREE.Vector3(x, 0, 0),
    new THREE.Vector3(x, y, 0),
    new THREE.Vector3(0, y, 0),
  ];
  const lines = drawLines(sideLineMaterial)()(points);
  lines.name = "lines";
  group.add(lines);
  group.translateX(-0.25)
  group.translateZ(-0.25)
  group.translateY(-0.25)

  //   let mesh, geometry, material;
  //   //创建一个立方体几何对象Geometry
  //   geometry = new THREE.BoxGeometry(100, 80, 50);
  //   meshs.push(new THREE.Mesh(geometry, material));

  //   let cylinder = new THREE.CylinderGeometry(50, 50, 100, 25);
  //   let material3 = new THREE.MeshLambertMaterial({
  //     color: 0xffff00,
  //   });
  //   mesh = new THREE.Mesh(cylinder, material3);
  //   mesh.position.set(120, 0, 0);
  //   meshs.push(mesh);

  // group.translateY(vector3.y);
  return group;
}
