import { BufferGeometry, PlaneGeometry, Mesh } from "three";

// const drawPlane =
//   (material = new BufferGeometry()) =>
//   (call) =>
//   (array) => {
//     array = call ? call(array) : array;
//     let geometry = new BufferGeometry();
//     geometry.setAttribute(
//       "position",
//       new BufferAttribute(new Float32Array(array), 3)
//     );

//     return new Mesh(geometry, material);
//   };
const drawPlane =
  (material = new BufferGeometry()) =>
  (width) =>
  (height) => {
    return new Mesh(new PlaneGeometry(width, height, 32), material);
  };

export default drawPlane;
