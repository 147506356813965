const settingProps = [
  {
    label: "盒子长/cm",
    name: "boxX",
    inputProps: { step: 1 },
  },
  {
    label: "盒子高/cm",
    name: "boxY",
    inputProps: { step: 1 },
  },
  {
    label: "盒子宽/cm",
    name: "boxZ",
    inputProps: { step: 1 },
  },
  {
    label: "加速度/m/s^2",
    name: "g",
    inputProps: { step: 0.1 },
  },
];
export default settingProps;
