<template>
  <v-form>
    <v-container class="grey lighten-5 mb-6">
      <v-row>
        <v-col
          v-for="(settingProp, index) in settingProps"
          :key="index"
          cols="3"
          sm="3"
        >
          <v-text-field
            type="number"
            :label="settingProp.label"
            v-model.number="settings[settingProp.name]"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-row
      v-for="(ballProp, index) in ballProps"
      :key="index"
      style="margin-left:30px"
      align="center"
    >
      <v-col cols="4" sm="4">
        <v-text-field
          type="number"
          label="粒径/cm"
          v-model.number="ballProp.grainSize"
        ></v-text-field>
      </v-col>
      <v-col cols="4" sm="4">
        <v-text-field
          type="number"
          label="数量/个"
          v-model.number="ballProp.count"
        ></v-text-field>
      </v-col>
      <v-col cols="4" sm="4">
        <v-btn depressed color="error" @click="deleteBallProp(index)">
          删除
        </v-btn>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-btn
        :disabled="btnDisabled"
        style="margin:5px"
        depressed
        color="primary"
        @click="addBallProp"
      >
        添加
      </v-btn>
      <v-btn
        :disabled="btnDisabled"
        style="margin:5px"
        depressed
        color="primary"
        @click="clickAdjust"
      >
        调整
      </v-btn>
      <v-btn
        :disabled="btnDisabled"
        style="margin:5px"
        depressed
        color="primary"
        @click="restore"
      >
        重置
      </v-btn>
    </div>
    <div class="text-center">
      <v-btn
        :disabled="btnDisabled"
        style="margin:5px"
        depressed
        color="primary"
        @click="clickOk"
      >
        确定
      </v-btn>
      <v-btn
        :disabled="btnDisabled"
        style="margin:5px"
        depressed
        color="primary"
        @click="clickReplay"
      >
        重播
      </v-btn>
      <v-btn
        :disabled="btnDisabled"
        style="margin:5px"
        depressed
        color="primary"
        @click="clickShake"
      >
        摇动
      </v-btn>
    </div>
    <v-container class="grey lighten-5 mb-6">
      <v-row>
        <v-col cols="6" sm="6">
          <v-text-field
            disabled
            label="表观密度/t/m^3"
            :value="settings.faceDensity"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6">
          <v-text-field
            disabled
            label="总重量/kg"
            :value="showSumMass"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="grey lighten-5 mb-6">
      <v-row>
        <v-col cols="6" sm="6">
          <v-text-field
            disabled
            label="堆积密度/t/m^3"
            :value="showSolidDensity"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6">
          <v-text-field
            disabled
            label="空隙率/%"
            :value="showVoidRatio"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import axios from 'axios'
import settingProps from '@/config/settings.js'
import { localStore } from '@/utils/storeUtils.js'
import {
  defaultBallProps,
  defaultSettings,
  getUriPrefix,
} from '@/config/defaultConfig.js'

export default {
  name: 'TopBar',
  props: {
    settings: {
      type: Object,
      default: null,
    },
    ballProps: {
      type: Array,
      default: () => [],
    },
    sumMass: {
      type: Number,
    },
    voidRatio: {
      type: Number,
    },
    btnDisabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      settingProps: settingProps,
      storeBallProps: localStore('ballProps'),
      storeSettings: localStore('settings'),
    }
  },
  computed: {
    // 总重量
    showSumMass() {
      return this.sumMass >= 0 ? this.sumMass.toFixed(2) : '数据有误'
    },
    // 堆积密度
    showSolidDensity() {
      return this.voidRatio >= 0
        ? (
            this.settings.faceDensity -
            this.settings.faceDensity * this.voidRatio
          ).toFixed(2)
        : '数量过少'
    },
    // 空隙率
    showVoidRatio() {
      return this.voidRatio >= 0
        ? (this.voidRatio * 100).toFixed(2)
        : '结果不准确'
    },
  },
  methods: {
    addBallProp: function() {
      this.ballProps.push({ count: 100, grainSize: 1 })
    },
    clickAdjust: function() {
      // const uri =
      //   getUriPrefix() +
      //   '/estimate/ws' +
      //   '?token=' +
      //   localStorage.getItem('token')
      // const client = new WebSocket(uri)
      // client.onopen = () => {
      //   client.send(
      //     JSON.stringify({ settings: this.settings, ballProps: this.ballProps })
      //   )
      // }
      // client.onmessage = (evt) => {
      //   if (evt.data === undefined || evt.data === '') {
      //     return
      //   }
      //   const res = JSON.parse(evt.data)
      //   console.log(res)
      //   this.ballProps.forEach(
      //     (ball) => (ball.count = Math.floor(ball.count * res))
      //   )
      // }
      const uri =
        getUriPrefix()+
        '/simulation/estimate' +
        '?token=' +
        localStorage.getItem('token')
      axios
        .post(uri, { settings: this.settings, ballProps: this.ballProps })
        .then((response) => {
          const c = response.data
          this.ballProps.forEach(
            (ball) => (ball.count = Math.floor(ball.count * c))
          )
        })
        .catch((err) => console.log(err))
    },
    deleteBallProp: function(index) {
      this.ballProps.splice(index, 1)
    },
    restore: function() {
      this.$emit('clickRestore')
    },
    clickReplay: function() {
      this.$emit('clickReplay')
    },
    clickShake: function() {
      this.$emit('clickShake')
    },
    clickOk: function() {
      this.storeSettings(this.settings || defaultSettings)
      this.storeBallProps(this.ballProps || defaultBallProps)
      this.$emit('changeKey')
      this.$emit('disableBtns')
    },
  },
}
</script>
