import { Line, BufferGeometry } from "three";

const drawLines =
  (material = new BufferGeometry()) =>
  (vector3) =>
  (points) => {
    // 向量运算
    if (vector3) {
      // 防止副作用
      points = points.map((p) => p.clone());
      points.forEach((p) => p.add(vector3));
    }
    return new Line(new BufferGeometry().setFromPoints(points), material);
  };

export default drawLines;
