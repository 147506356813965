import Vue from 'vue'
import GravelThree from '@/GravelThree.vue';
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(GravelThree)
}).$mount('#app')
