import { WebGLRenderer } from "three";
/**
 * 创建渲染器对象
 */
export default function createRenderer(width, height) {
  let renderer = new WebGLRenderer();
  renderer.setSize(width, height); //设置渲染区域尺寸
  renderer.setClearColor(0xb9d3ff, 1); //设置背景颜色
  return renderer;
}
